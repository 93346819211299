import axios from 'axios'
import React, { Context, useEffect, useState } from 'react'
import { User } from '../../model/User'
import { Roles } from './Roles'

interface IAuthProviderProps {
    children?: React.ReactNode
}

export interface IAuthContext {
    isLoaded: boolean,
    isAuthenticated: boolean,
    isQuantemAdmin: boolean,
    user?: User,
    logout: () => void,
}

export const AuthContext: Context<IAuthContext> = React.createContext<IAuthContext>({
    isLoaded: false,
    isAuthenticated: false,
    isQuantemAdmin: false,
    logout: () => null
})

function getCookie(key: string): string | undefined {
    const name: string = key + '='
    const decodedCookie: string = decodeURIComponent(document.cookie)
    const ca: string[] = decodedCookie.split(';')

    for (let c of ca) {
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }

        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }

    return undefined
}

function AuthProvider(props: IAuthProviderProps) {

    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [isQuantemAdmin, setIsQuantemAdmin] = useState<boolean>(false)
    const [user, setUser] = useState<User | null>(null)

    const request: (options: any) => Promise<any> = async (options: any) => {
        const headers: Headers = new Headers({
            'Content-Type': 'application/json',
        })

        const defaults: { headers: Headers } = {headers: headers}
        options = {...defaults, ...options}

        let response = await fetch(options.url, options)
        if (!response.ok) {
            let json = await response.json()
            return await Promise.reject(json)

        }
        return response.json()
    };

    const getCsrfToken = () => {
        return request({
            url: '/api/user/csrf-token',
            method: 'GET',
        });
    };

    const getCurrentUser = (): Promise<User> => {
        return request({
            url: '/api/user/me',
            method: 'GET',
        }) as Promise<User>;
    };

    useEffect(() => {
        const authenticate = () => {
            getCsrfToken()
                .then(() => getCurrentUser())
                .then((userResponse) => {
                    setIsAuthenticated(true);
                    setUser(userResponse);
                    updateUserQuantemAdminStatus(userResponse);
                })
                .catch(() => {
                    setIsAuthenticated(false);
                    setUser(null);
                    setIsQuantemAdmin(false);
                })
                .finally(() => {
                    setIsLoaded(true);
                });
        };

        authenticate();

        // Refresh token every 5 min
        const interval = window.setInterval(authenticate, 5 * 60 * 1000);

        return () => window.clearInterval(interval);
    }, []);

    const logout: () => void = (): void => {
        axios.post('/api/logout')
            .finally(() => {
                setIsAuthenticated(false)
                setUser(null)
                clearAuthCookies()
                window.location.reload()
            })
    }

    function clearAuthCookies() {
        function delete_cookie(name: string) {
            document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        }

        delete_cookie('XSRF-TOKEN')
    }

    const getValue: () => IAuthContext = (): IAuthContext => {
        return {
            isLoaded: isLoaded,
            isAuthenticated: isAuthenticated,
            isQuantemAdmin: isQuantemAdmin,
            user: user ?? undefined,
            logout: logout
        }
    }

    function updateUserQuantemAdminStatus(user: User | null) {
        if (user !== null) {
            let filteredAuthorities = user.authorities.filter((authority) => {
                return authority.authority === Roles.QUANTEM_ADMIN
            })

            setIsQuantemAdmin(filteredAuthorities.length > 0)
        } else {
            setIsQuantemAdmin(false)
        }
    }

    return (
        <AuthContext.Provider value={getValue()}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
