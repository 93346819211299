import {
    CalculationResultResponse,
    fetchCalculationResult,
    generateFileUploadUrlsForFilenames,
    CreateResponse,
    uploadFileToPresignedUrl
} from '../providers/EluentDataProvider'

export type TCalculationCode = string;

export interface CalculableFile {
    fileId: number;
}

class UploadError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = 'UploadError'
    }
}

function findPresignedUrlForFile(file: File, presignedUrls: CreateResponse[]): CreateResponse | undefined {
    return presignedUrls.find((presignedUrl) => presignedUrl.fileName === file.name)
}

function uploadFilesToPresignedUrls(files: File[], presignedUrls: CreateResponse[]): Promise<CalculableFile[]> {
    let presignedUrlsArray = presignedUrls
    return new Promise((resolve, reject) => {
        const promises = files.map((file) => {
            const container = findPresignedUrlForFile(file, presignedUrlsArray)
            if (container === undefined) {
                reject(new UploadError('Failed to upload files, if this happens again, please contact support.'))
            }
            presignedUrlsArray = presignedUrlsArray.filter(url => url !== container) // remove the element to support duplicate filenames
            const fileId = container?.id

            if (fileId === undefined) {
                reject(new UploadError('Failed to upload files, if this happens again, please contact support.'))
            }

            return uploadFileToPresignedUrl(file, fileId!)
                .then(() => {
                    return {
                        fileId: container!.id,
                    }
                })
        })

        Promise.all(promises)
            .then((files) => resolve(files))
            .catch(reject)
    })
}

function uploadFiles(files: File[]): Promise<CalculableFile[]> {
    return generateFileUploadUrlsForFilenames(files.map((file) => file.name))
        .then((urls) => {
            return uploadFilesToPresignedUrls(files, urls)
        })
}

export function startCalculationProcessAndUploadFiles(files: File[]): Promise<CalculableFile[]> {
    return uploadFiles(files)
}

export function checkIfCalculationFinished(code: TCalculationCode): Promise<CalculationResultResponse> {
    return fetchCalculationResult(code)
}
