import React from 'react'
import { useLocalization } from '../../hooks/useLocalization'

interface DateLabelProps {
    className?: string
    date?: number
}

function DateLabel(props: DateLabelProps) {

    const l10n = useLocalization()

    function getDateString(): string {
        if (props.date === null || props.date === undefined) {
            return ''
        }
        const epoch = props.date
        const date = new Date(0)
        date.setUTCSeconds(epoch / 1000)
        return `${date.getDate()} ${formatMonth(date.getMonth())} ${date.getFullYear()}`
    }

    function formatMonth(month: number): string {
        switch (month) {
            case 0:
                return l10n.months.january
            case 1:
                return l10n.months.february
            case 2:
                return l10n.months.march
            case 3:
                return l10n.months.april
            case 4:
                return l10n.months.may
            case 5:
                return l10n.months.june
            case 6:
                return l10n.months.july
            case 7:
                return l10n.months.august
            case 8:
                return l10n.months.september
            case 9:
                return l10n.months.october
            case 10:
                return l10n.months.november
            case 11:
                return l10n.months.december
            default:
                return ''
        }
    }

    return (
        <span className={props.className}>
            {getDateString()}
        </span>
    )
}

export default DateLabel
