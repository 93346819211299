import { Modal, Table } from 'flowbite-react'
import React, { useState } from 'react'
import { ReactComponent as CheckmarkIcon } from '../../../assets/img/icons/checkmark-icon.svg'
import { ReactComponent as TrashIcon } from '../../../assets/img/icons/trash-icon-primary.svg'
import IconButton from '../../../components/buttons/IconButton'
import ButtonPrimaryOutline from '../../../components/buttons/primary/ButtonPrimaryOutline'
import DropdownList, { DropdownListItem } from '../../../components/dropdowns/DropdownList'
import DateLabel from '../../../components/functional/DateLabel'
import QuantemScaleLoader from '../../../components/loadingIndicators/QuantemScaleLoader'
import { useLocalization } from '../../../hooks/useLocalization'
import { OrganizationListItemDto } from '../../../model/admin/OrganizationListItemDto'
import { UserListItemDto } from '../../../model/admin/UserListItemDto'
import { deleteUserListItem, updateUserListItem } from '../../../providers/AdminProvider'

interface AdminUsersTableRowProps {
    user: UserListItemDto,
    currentOrganization: OrganizationListItemDto,
    organizations: OrganizationListItemDto[],
    updateUser: (listItem: UserListItemDto) => void,
    deleteUser: (listItem: UserListItemDto) => void
}

function AdminUsersTableRow(props: AdminUsersTableRowProps) {

    const sharedStyle = 'text-c1 font-semibold text-textDark-100'
    const l10n = useLocalization().admin.users
    const [name, setName] = useState<string>(props.user.name)
    const [email, setEmail] = useState(props.user.email)
    const [selectedOrganization, setSelectedOrganization] = useState(props.currentOrganization)
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    function mapTiersToDropwdownItems(organizations: OrganizationListItemDto[]): DropdownListItem[] {
        return organizations.map((org): DropdownListItem => {
            return {
                title: org.name,
                object: org
            }
        })
    }

    function getSelected() {
        return {
            title: selectedOrganization.name,
            object: selectedOrganization
        }
    }

    function setSelected(object: DropdownListItem) {
        setSelectedOrganization(object.object)
    }

    function canSave(): boolean {
        return name !== props.user.name
            || email !== props.user.email
            || selectedOrganization.id !== props.user.organizationId
    }

    function saveUser() {
        const modifiedUser: UserListItemDto = {
            id: props.user.id,
            name: name,
            email: email,
            organizationId: selectedOrganization.id,
            lastCalculation: props.user.lastCalculation
        }
        setIsSaving(true)
        updateUserListItem(modifiedUser)
            .then(props.updateUser)
            .catch(window.alert)
            .finally(() => setIsSaving(false))
    }

    function deleteUser() {
        setIsDeleting(true)
        deleteUserListItem(props.user)
            .then(() => {
                props.deleteUser(props.user)
            })
            .catch(window.alert)
            .finally(() => setIsDeleting(false))
    }

    function compileModal(): JSX.Element {
        return <Modal show={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
            <Modal.Header className="text-error-500">{l10n.deleteConfirm}</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                        {l10n.deleteConfirmExplanation}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonPrimaryOutline
                    className="border-error-500 text-textLight-100 bg-error-500 hover:bg-error-200 h-[40px] w-[150px]"
                    text={l10n.delete}
                    onClick={() => {
                        setShowDeleteConfirm(false)
                        deleteUser()
                    }
                    }
                />
                <ButtonPrimaryOutline
                    className="h-[40px] w-[150px]"
                    text={l10n.cancel}
                    onClick={() => setShowDeleteConfirm(false)}
                />
            </Modal.Footer>
        </Modal>
    }

    function compileRow() {
        return <Table.Row className="bg-white shadow dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className={`${sharedStyle} cursor-pointer`}>
                <span
                    className="w-full h-[40px] text-c1 border-none rounded-md hover:border-background-light-100 p-2"
                >
                    {props.user.id}
                </span>
            </Table.Cell>
            <Table.Cell className={`${sharedStyle} cursor-pointer`}>
                <input
                    className="w-full h-[40px] text-c1 border-none rounded-md hover:border-background-light-100 p-2"
                    type="text"
                    lang="en-US"
                    value={name}
                    onChange={event => setName(event.target.value)}
                />
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                {/*<input*/}
                {/*    className="w-full h-[40px] text-b2 hover:border rounded-md hover:border-background-light-100 p-2"*/}
                {/*    type="text"*/}
                {/*    lang="en-US"*/}
                {/*    value={email}*/}
                {/*    onChange={event => setEmail(event.target.value)}*/}
                {/*/>*/}
                <span className={sharedStyle}>{props.user.email}</span>
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <DropdownList
                    className="text-c1 w-full h-[40px]"
                    items={mapTiersToDropwdownItems(props.organizations)}
                    title={selectedOrganization.name}
                    getSelected={getSelected}
                    setSelected={setSelected}
                />
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <span className={sharedStyle}>{selectedOrganization.id}</span>
            </Table.Cell>
            <Table.Cell className={sharedStyle}>
                <DateLabel className={sharedStyle} date={props.user.lastCalculation}/>
            </Table.Cell>
            <Table.Cell className={`${sharedStyle} flex flex-row`}>
                <div className="scale-75">
                    <IconButton
                        className="bg-primary-700 hover:bg-primary-400 disabled:bg-primary-100"
                        isDisabled={!canSave()}
                        onClick={() => {
                            saveUser()
                        }}>
                        {
                            isSaving
                                ? <div className="flex scale-50"><QuantemScaleLoader colorOverride="#FFFFFF"/></div>
                                : <CheckmarkIcon className="fill-backgroundLight-100"/>
                        }

                    </IconButton>
                </div>
                <div className="scale-75">
                    <IconButton
                        className="bg-error-700 hover:bg-error-400 disabled:bg-error-50"
                        isDisabled={false}
                        onClick={() => {
                            setShowDeleteConfirm(true)
                        }}>
                        {isDeleting
                            ? <div className="flex scale-50"><QuantemScaleLoader colorOverride="#FFFFFF"/></div>
                            : <TrashIcon className="fill-textLight-100"/>
                        }
                    </IconButton>
                </div>
            </Table.Cell>
        </Table.Row>
    }

    return (
        <>
            {compileModal()}
            {compileRow()}
        </>
    )
}

export default AdminUsersTableRow
