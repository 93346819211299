import React from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useLocalization } from '../../hooks/useLocalization'
import LogoThinTransparent from '../images/logos/LogoThinTransparent'
import NavigationLink from './NavigationLink'

export function Navbar() {

    const l10n = useLocalization().navigation
    const auth = useAuth()

    function handleLogoutClick(event: React.MouseEvent<Element>): void {
        event.preventDefault()
        auth.logout()
    }

    function displayAdminLinkIfNecessary(): JSX.Element | null {
        if (auth.isQuantemAdmin) {
            return (<NavigationLink to="admin" icon="people" title={l10n.admin}/>)
        } else {
            return null
        }
    }

    return (
        <div
            className="bg-primary-800 text-textLight-200 w-64 flex flex-col sidebar space-y-6 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">
            <a href="https://quantem.co"><LogoThinTransparent className="flex m-auto items-center px-4 pt-4"/></a>
            <nav className="border-t border-otherDark-200 flex-grow">
                <NavigationLink to="calculation" icon="home" title={l10n.calculation}/>
                <NavigationLink to="history" icon="folder" title={l10n.history}/>
                <NavigationLink to="https://quantem-public.s3.eu-central-1.amazonaws.com/User_manual_online_LATEST.pdf"
                                target="_blank" icon="hint" title={l10n.manual}/>
                {displayAdminLinkIfNecessary()}
                <NavigationLink icon="logout" title={l10n.logout} onClick={handleLogoutClick}/>
            </nav>
            <a className="w-max text-textLight-100 m-auto py-8 font-normal text-xs"
               href="mailto:support@quantem.co">support@quantem.co</a>
        </div>
    )
}
