import React from 'react';
import Card, { CardProps } from '../../../components/card/Card';

interface CalculationCardProps extends CardProps {
    title: string;
    titleClassName?: string;
    containerClassName?: string;
}

function CalculationCard(props: CalculationCardProps) {
    return (
        <Card className={`flex ${props.className}`}>
            <div className={props.titleClassName ?? "w-1/4 my-auto ml-16 text-b2"}>{props.title}</div>
            <div className={props.containerClassName ?? "w-3/4 my-auto mr-16"}>{props.children}</div>
        </Card>
    );
}

export default CalculationCard;
