import { ReactComponent as ChevronDownIcon } from '../../assets/img/icons/chevrons/chevron-down.svg'
import { ReactComponent as DocumentIcon } from '../../assets/img/icons/document-icon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/img/icons/download-icon.svg'
import DateLabel from '../../components/functional/DateLabel'
import TimeLabel from '../../components/functional/TimeLabel'
import { useLocalization } from '../../hooks/useLocalization'
import { CalculationStatus } from '../../model/calculation/CalculationStatus'
import { fetchDownloadUrlForFile } from '../../providers/HistoryProvider'

interface IHistoryRowProps {
    startTime: number;
    filename: string;
    conditions: string;
    templateDate?: string;
    status: CalculationStatus;
    fileId: number;
    analytes: number;
    user: string;
    conditionsClickHandler: React.MouseEventHandler;
    isExpanded: boolean
}

function HistoryRow(props: IHistoryRowProps): JSX.Element {
    const sharedStyle = 'text-c2 px-4 py-2.5 border-b border-division'
    const l10n = useLocalization().calculationState

    function downloadFile(fetchInput: boolean) {
        fetchDownloadUrlForFile(props.fileId, fetchInput, props.filename)
        // .then((url) => { window.location.href = url })
    }

    const getInitials = (fullName: string) => {
        const allNames = fullName.trim().split(' ')
        return allNames.reduce((acc, curr, index) => {
            if (index === 0 || index === allNames.length - 1) {
                acc = `${acc}${curr.charAt(0).toUpperCase()}`
            }
            return acc
        }, '')
    }

    function statusFormatter(status: CalculationStatus): string {
        switch (status) {
            case CalculationStatus.inQueue:
                return l10n.inQueue
            case CalculationStatus.running:
                return l10n.calculating
        }

        return `${status}`
    }

    function renderStatusCell(status: CalculationStatus) {
        switch (status) {
            case CalculationStatus.failed:
                return (
                    <span className="uppercase text-error-900 text-c1">{l10n.error}</span>
                )

            case CalculationStatus.finished:
                return (
                    <div className="flex cursor-pointer" onClick={(event) => {
                        event.preventDefault()
                        downloadFile(false)
                    }}>
                        <span className="uppercase mx-auto text-error-900 text-c1"
                              aria-hidden="true"><DownloadIcon/></span>
                    </div>
                )
        }

        return (
            <span className="uppercase text-primary-800 text-c1">{statusFormatter(status)}</span>
        )
    }

    function renderTemplateDateIfNeeded(): JSX.Element {
        if (props.templateDate !== undefined && props.templateDate !== null) {
            return <span className="text-c2 text-textDark-300">{props.templateDate}</span>
        } else {
            return <div/>
        }
    }

    return (
        <tr className={`${props.isExpanded ? 'bg-select ' : 'bg-backgroundLight-100 '} hover:bg-hover`}>
            <td className={` ${sharedStyle}`}>
                <DateLabel date={props.startTime}/>
                <TimeLabel className="text-textDark-300 pl-1" time={props.startTime}/>
            </td>
            <td className={` ${sharedStyle}`}>
                <div className="flex space-x-2 items-center cursor-pointer whitespace-nowrap" onClick={(event) => {
                    event.preventDefault()
                    downloadFile(true)
                }}>
                    <span><DocumentIcon/></span>
                    <span>{props.filename}</span>
                </div>
            </td>
            <td className={` ${sharedStyle} w-32`}>
                <div className="flex items-center cursor-pointer max-w-sm" onClick={props.conditionsClickHandler}>
                    <span className="flex-grow truncate">{props.conditions} {renderTemplateDateIfNeeded()}</span>
                    <span className={`scale-75 ${props.isExpanded ? 'rotate-180' : ''}`}><ChevronDownIcon/></span>
                </div>
            </td>
            <td className={`text-center ${sharedStyle}`}>
                {renderStatusCell(props.status)}
            </td>
            <td className={`text-center ${sharedStyle}`}>{props.analytes}</td>
            <td className={`text-center ${sharedStyle}`}>
                <div className="flex flex-col items-center">
                    <div
                        className="rounded-full w-7 h-7 flex flex-col bg-primary-50 text-primary-600 text-c2 font-semibold">
                        <span className="m-auto">{getInitials(props.user)}</span>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default HistoryRow
