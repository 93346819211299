import React, { HTMLAttributeAnchorTarget, MouseEventHandler } from 'react'
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom'
import { ReactComponent as FolderIconActive } from '../../assets/img/icons/folder-icon-active.svg'
import { ReactComponent as FolderIcon } from '../../assets/img/icons/folder-icon.svg'
import { ReactComponent as HintIcon } from '../../assets/img/icons/hint-icon.svg'
import { ReactComponent as HomeIconActive } from '../../assets/img/icons/home-icon-active.svg'
import { ReactComponent as HomeIcon } from '../../assets/img/icons/home-icon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/img/icons/logout-icon.svg'
import { ReactComponent as PeopleIconActive } from '../../assets/img/icons/people-icon-active.svg'
import { ReactComponent as PeopleIcon } from '../../assets/img/icons/people-icon.svg'

type TNavigationLinkIcon = 'home' | 'folder' | 'people' | 'hint' | 'logout';

export interface INavigationLinkProps {
    to?: string;
    icon: TNavigationLinkIcon,
    title: string,
    onClick?: MouseEventHandler,
    target?: HTMLAttributeAnchorTarget | undefined;
}

function svgForIcon(icon: TNavigationLinkIcon, isActive: boolean): JSX.Element {
    switch (icon) {
        case 'home':
            return isActive ? <HomeIconActive/> : <HomeIcon/>

        case 'folder':
            return isActive ? <FolderIconActive/> : <FolderIcon/>

        case 'people':
            return isActive ? <PeopleIconActive/> : <PeopleIcon/>

        case 'hint':
            return <HintIcon/>

        case 'logout':
            return <LogoutIcon/>
    }
}

function NavigationLink(props: INavigationLinkProps) {

    const resolved = useResolvedPath(props.to ?? '')
    const match = useMatch({path: resolved.pathname, end: true})
    const isActive = match !== null

    const activeClasses = 'bg-selectDark text-textLight-100'
    const tailwindClasses = 'block py-2 px-4 flex flex-row rounded transition duration-100 hover:bg-hoverDark active:bg-selectedDark'

    const innerHtml = (
        <div className="flex flex-row items-center">
            {svgForIcon(props.icon, isActive)}
            <span className="pl-2">{props.title}</span>
        </div>
    )

    return props.to?.startsWith('http://') || props.to?.startsWith('https://')
        ? <a className={tailwindClasses} href={props.to} target={props.target} onClick={props.onClick}>{innerHtml}</a>
        : <NavLink
            className={() => {
                return isActive ? `${activeClasses} ${tailwindClasses}` : tailwindClasses
            }}
            to={props.to ?? ''}
            target={props.target}
            onClick={props.onClick}
        >{innerHtml}
        </NavLink>
}

export default NavigationLink
