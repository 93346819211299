import { saveAs } from 'file-saver'
import JSZip from 'jszip'

interface ISource {
    fileName: string;
    url: string;
}

interface IFileContent {
    fileName: string;
    blob: Promise<Blob>;
}

const downloadMany: (sources: ISource[]) => Promise<IFileContent[]> = (sources: ISource[]): Promise<IFileContent[]> => {
    return Promise.all(sources.map((source: ISource) => fetch(source.url).then((res: Response) => ({
        fileName: source.fileName,
        blob: res.blob(),
    }))))
}

function generateFileName(fileName: string, existingNames: string[]): string {
    const existingCount = existingNames.filter(name => name === fileName).length
    if (existingCount == 0) {
        return fileName
    }
    const extension = fileName.match(/\.[^/.]+$/)![0]
    let newName = fileName.replace(/\.[^/.]+$/, '')
    newName = `${newName} (${existingCount})${extension}`
    return newName
}

const exportZip: (files: IFileContent[]) => void = (files: IFileContent[]) => {
    const zip: JSZip = JSZip()
    const currentDate: string = new Date().toISOString()
    const filenames: string[] = []
    files.forEach(({blob, fileName}: IFileContent) => {
        const newName = generateFileName(fileName, filenames)
        filenames.push(fileName)
        zip.file(newName, blob)
    })
    zip.generateAsync({type: 'blob'}).then((zipFile: Blob) => {
        const fileName: string = `quantem-${currentDate}.zip`

        return saveAs(zipFile, fileName)
    })
}

export const downloadAndZip: (sources: ISource[]) => void = (source: ISource[]) => {
    return downloadMany(source).then(exportZip)
}
