import React, { useState } from 'react'
import { ReactComponent as SortUpIcon } from '../../assets/img/icons/sort-up-icon.svg'
import { useLocalization } from '../../hooks/useLocalization'
import { Eluent } from '../../model/eluent/Eluent'
import { ModelInfo } from '../../model/eluent/ModelInfo'
import { ISolventData } from '../../model/solventData/ISolventData'
import { HistoryResponse } from '../../providers/HistoryProvider'
import HistoryRow from './HistoryRow'
import HistoryRowExpansion from './HistoryRowExpansion'

interface HistoryTableProps {
    history: HistoryResponse[]
    solventData?: ISolventData
}

type THistorySortKey = 'date' | 'filename' | 'user' | 'status' | 'analytes';

function HistoryTable(props: HistoryTableProps) {

    const [sortingKey, setSortingKey] = useState<THistorySortKey>('date')
    const [isAscending, setIsAscending] = useState(false)
    const [calculationHistory, setCalculationHistory] = useState(props.history)
    const l10n = useLocalization().history.table

    function toggleSort(key: THistorySortKey): void {
        if (sortingKey === key) {
            setIsAscending(!isAscending)

            return
        }

        setSortingKey(key)
        setIsAscending(true)
    }

    function sortArrowFor(key: THistorySortKey) {
        if (key === sortingKey) {
            return isAscending ? <SortUpIcon/> : <SortUpIcon className="rotate-180"/>
        }

        return <SortUpIcon className="opacity-0"/>
    }

    function sortedHistory(): HistoryResponse[] {
        switch (sortingKey) {
            case 'date':
                return calculationHistory.sort((lhs, rhs) => {
                    return isAscending
                        ? lhs.startTime - rhs.startTime
                        : rhs.startTime - lhs.startTime
                })

            case 'filename':
                return calculationHistory.sort((lhs, rhs) => {
                    return isAscending
                        ? lhs.fileName.localeCompare(rhs.fileName)
                        : rhs.fileName.localeCompare(lhs.fileName)
                })

            case 'status':
                return calculationHistory.sort((lhs, rhs) => {
                    return isAscending
                        ? lhs.status.localeCompare(rhs.status)
                        : rhs.status.localeCompare(lhs.status)
                })

            case 'analytes':
                return calculationHistory

            case 'user':
                return calculationHistory.sort((lhs, rhs) => {
                    return isAscending
                        ? lhs.userName.localeCompare(rhs.userName)
                        : rhs.userName.localeCompare(lhs.userName)
                })
        }
    }

    function constructRowFrom(history: HistoryResponse, key: number): JSX.Element {
        return (
            <HistoryRow
                key={key}
                startTime={history.startTime}
                filename={history.fileName}
                conditions={history.eluent.name ?? l10n.customConditions}
                templateDate={history.eluent.templateDate}
                status={history.status}
                fileId={history.id}
                analytes={history.rowCount}
                user={history.userName}
                isExpanded={history.isExpanded ?? false}
                conditionsClickHandler={(event) => {
                    event.preventDefault()
                    handleConditionsClicked(history)
                }}
            />
        )
    }

    function handleConditionsClicked(history: HistoryResponse): void {
        const histories = calculationHistory
        const indexOfHistory = histories.indexOf(history)
        if (indexOfHistory > -1 && indexOfHistory < histories.length) {
            history.isExpanded = !(history.isExpanded ?? false)
            histories[indexOfHistory] = history
            setCalculationHistory([...histories])
        }
    }

    function constructRowExpansion(eluentData: Eluent, key: number, modelInfo?: ModelInfo[]): JSX.Element {
        return (
            <HistoryRowExpansion key={key} eluentData={eluentData} modelInfo={modelInfo} solventData={props.solventData}/>
        )
    }

    function renderRows(): JSX.Element[] {
        let expandedRows = 0
        const response: JSX.Element[] = []
        sortedHistory().forEach((history, index) => {
            const row = constructRowFrom(history, index + expandedRows)
            response.push(row)
            if (history.isExpanded) {
                expandedRows += 1
                const expansion = constructRowExpansion(history.eluent,index + expandedRows, history.modelInfo)
                response.push(expansion)
            }
        })

        return response
    }

    function renderTableHeader(): JSX.Element {
        const sharedStyle = 'text-c1 font-semibold text-textDark-100 border-b border-division pt-4 pb-2 px-4'
        return (
            <thead>
            <tr>
                <th scope="col"
                    className={`w-32 text-left cursor-pointer ${sharedStyle}`}
                    onClick={() => {
                        toggleSort('date')
                    }}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.date}</span>
                        <span className="">{sortArrowFor('date')}</span>
                    </div>
                </th>
                <th
                    className={`text-left cursor-pointer ${sharedStyle}`}
                    onClick={() => {
                        toggleSort('filename')
                    }}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.inputFile}</span>
                        <span className="">{sortArrowFor('filename')}</span>
                    </div>
                </th>
                <th className={`text-left ${sharedStyle}`}>{l10n.conditions}</th>
                <th
                    className={`w-32 text-center cursor-pointer ${sharedStyle}`}
                    onClick={() => {
                        toggleSort('status')
                    }}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.results}</span>
                        <span className="">{sortArrowFor('status')}</span>
                    </div>
                </th>
                <th
                    className={`w-32 text-center cursor-pointer ${sharedStyle}`}
                    onClick={() => {
                        toggleSort('analytes')
                    }}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.analytes}</span>
                        <span className="">{sortArrowFor('analytes')}</span>
                    </div>
                </th>
                <th
                    className={`w-32 pl-6 text-center cursor-pointer ${sharedStyle}`}
                    onClick={() => {
                        toggleSort('user')
                    }}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.user}</span>
                        <span className="">{sortArrowFor('user')}</span>
                    </div>
                </th>
            </tr>
            </thead>
        )
    }

    return (
        <table className="table-fixed rounded bg-backgroundLight-100 shadow-card mx-4">
            {renderTableHeader()}
            <tbody>
            {renderRows()}
            </tbody>
        </table>
    )
}

export default HistoryTable
