import React from 'react'

interface TimeLabelProps {
    className?: string
    time: number
}

function TimeLabel(props: TimeLabelProps) {
    function getTimeString(): string {
        const epoch = props.time
        const date = new Date(0)
        date.setUTCSeconds(epoch / 1000)
        const minutes = date.getMinutes()
        return `${date.getHours()}:${minutes < 10 ? `0${minutes}` : minutes}`
    }

    return (
        <span className={props.className}>
            {getTimeString()}
        </span>
    )
}

export default TimeLabel
