import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

export interface ModalDialogProps {
    className?: string;
    children?: React.ReactNode;
    isOpen: boolean;
    closeModal: () => void;
}

export default function ModalDialog(props: ModalDialogProps) {

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog
                as="div"
                className={`${props.className} fixed inset-0 z-10 overflow-y-auto`}
                onClose={props.closeModal}
            >
                <Dialog.Overlay className="fixed inset-0 bg-backgroundLight-200 opacity-60" />
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        {props.children}
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}
