import React from 'react'

const en = require('./locales/en.json')

export type l10n = typeof en;

interface ILocalizationProviderProps {
    children?: React.ReactNode;
}

export const LocalizationContext = React.createContext<l10n>(en)

const LocalizationProvider: React.FC<ILocalizationProviderProps> = (props: ILocalizationProviderProps) => {

    const value: l10n = en

    return (
        <LocalizationContext.Provider value={value}>
            {props.children}
        </LocalizationContext.Provider>
    )
}

export default LocalizationProvider;