import React, { useState } from 'react'
import { ReactComponent as OrganizationsIconActive } from '../../assets/img/icons/organizations-icon-active.svg'
import { ReactComponent as OrganizationsIcon } from '../../assets/img/icons/organizations-icon.svg'
import { ReactComponent as UsersIconActive } from '../../assets/img/icons/users-icon-active.svg'
import { ReactComponent as UsersIcon } from '../../assets/img/icons/users-icon.svg'
import SegmentedControl, { SegmentedControlItem } from '../../components/segmentedControl/SegmentedControl'
import { useLocalization } from '../../hooks/useLocalization'
import { OrganizationListItemDto, OrganizationTier } from '../../model/admin/OrganizationListItemDto'
import { UserListItemDto } from '../../model/admin/UserListItemDto'
import ContentContainer from '../base/ContentContainer'
import AdminOrganizationsTable from './tabs/AdminOrganizationsTable'
import AdminUsersTable from './tabs/AdminUsersTable'

interface AdministrationMainProps {
    users: UserListItemDto[],
    organizations: OrganizationListItemDto[]
    tiers: OrganizationTier[],
    updateUser: (listItem: UserListItemDto) => void,
    deleteUser: (listItem: UserListItemDto) => void,
    updateOrganization: (listItem: OrganizationListItemDto) => void
    deleteOrganization: (listItem: OrganizationListItemDto) => void
}

enum AdminPage {
    users,
    organizations
}

function AdministrationMain(props: AdministrationMainProps) {
    const l10n = useLocalization()
    const [currentPage, setCurrentPage] = useState(AdminPage.users)

    function renderSelectorControl(): JSX.Element {

        const items: SegmentedControlItem[] = [
            {
                label: l10n.admin.sections.users,
                activeIcon: <UsersIconActive/>,
                icon: <UsersIcon/>,
                isActive: currentPage === AdminPage.users,
                selectedCallback: () => {
                    setCurrentPage(AdminPage.users)
                }
            },
            {
                label: l10n.admin.sections.organizations,
                activeIcon: <OrganizationsIconActive/>,
                icon: <OrganizationsIcon/>,
                isActive: currentPage === AdminPage.organizations,
                selectedCallback: () => {
                    setCurrentPage(AdminPage.organizations)
                }
            }
        ]

        return <SegmentedControl items={items}/>
    }

    function renderContentTable(): JSX.Element {
        switch (currentPage) {
            case AdminPage.users:
                return <AdminUsersTable
                    users={props.users}
                    organizations={props.organizations}
                    updateUser={props.updateUser}
                    deleteUser={props.deleteUser}
                />

            case AdminPage.organizations:
                return <AdminOrganizationsTable
                    organizations={props.organizations}
                    tiers={props.tiers}
                    updateOrganization={props.updateOrganization}
                    deleteOrganization={props.deleteOrganization}
                />
        }
    }

    return (
        <ContentContainer className="min-h-screen" viewTitle={l10n.admin.title}>
            <div className="w-full h-full flex flex-col py-4 space-y-2">
                {renderSelectorControl()}
                <div className="w-full">
                    {renderContentTable()}
                </div>
            </div>
        </ContentContainer>
    )
}

export default AdministrationMain
