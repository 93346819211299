import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useAuth } from './hooks/useAuth'
import { useLocalization } from './hooks/useLocalization'
import Admin from './views/admin/Admin'
import ContentContainer from './views/base/ContentContainer'
import Calculation from './views/calculation/Calculation'
import History from './views/history/History'

export function CoreRoutes() {

    const l10n = useLocalization().navigation
    const auth = useAuth()
    const calculation = <ContentContainer viewTitle={l10n.calculation}><Calculation/></ContentContainer>
    const history = <ContentContainer viewTitle={l10n.history}><History/></ContentContainer>
    const admin = <Admin/>

    const adminRoute = auth.isQuantemAdmin
        ? <Route path="admin" element={admin}/>
        : <Route path="admin" element={<Navigate to="/calculation" replace/>}/>

    return (
        <Routes>
            <Route path="/" element={<Navigate to="/calculation" replace/>}/>
            <Route path="calculation" element={calculation}/>
            <Route path="history" element={history}/>
            {adminRoute}
            {/*<Route path="admin" element={admin}/>*/}
            {/*<Route path="*" element={<NoMatch />} />*/}
        </Routes>
    )
}
