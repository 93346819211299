import axios, { AxiosResponse } from 'axios'
import { CalculationStatus } from '../model/calculation/CalculationStatus'
import { Eluent } from '../model/eluent/Eluent'
import { ISolventData } from '../model/solventData/ISolventData'
import { TCalculationCode } from '../services/CalculationService'

export interface CalculationStartData {
    fileIds: number[],
    eluent: Eluent
}

export interface FileUploadUrlResponse {
    fileName: string;
    id: number;
    presignedUrl: string;
}

export interface CreateResponse {
    fileName: string;
    id: number;
}

export interface CalculationStartResponse {
    calculationCode?: string;
    didStart: boolean
    reservedRowCount?: number
    validations: CalculationStartResponseValidation[]
}

export interface CalculationStartResponseValidation {
    fileId: number
    isValid: boolean
    rowCount?: number
    validationErrors: CalculationStartResponseValidationError[]
}

export interface CalculationStartResponseValidationError {
    type: CalculationFileValidationErrorType
    fileName: string
    additionalInfo?: string
}

export enum CalculationFileValidationErrorType {
    NOT_ENOUGH_TAILORING_COMPOUNDS = 'NOT_ENOUGH_TAILORING_COMPOUNDS',
    FAILED_ROW_COUNT = 'FAILED_ROW_COUNT',
    EXCEEDED_ROW_COUNT = 'EXCEEDED_ROW_COUNT',
    INVALID_STRUCTURE = 'INVALID_STRUCTURE',

    RET_TIME_NEGATIVE = 'RET_TIME_NEGATIVE',
    RET_TIME_MISSING_OR_NAN = 'RET_TIME_MISSING_OR_NAN',

    SIGNAL_NEGATIVE = 'SIGNAL_NEGATIVE',
    SIGNAL_MISSING_OR_NAN = 'SIGNAL_MISSING_OR_NAN',

    CONCENTRATION_NEGATIVE = 'CONCENTRATION_NEGATIVE',

    ION_MODE_MISSING = 'ION_MODE_MISSING',
    ION_MODE_INVALID = 'ION_MODE_INVALID',
    SINGLE_MODE_ION_MODE_CONFLICT = 'SINGLE_MODE_ION_MODE_CONFLICT',

    INVALID_SMILES = 'INVALID_SMILES'
}

interface FileDownloadDTO {
    url: string,
    fileName: string
}

export interface CalculationResultResponse {
    code: string,
    status: CalculationStatus,
    downloadUrls?: FileDownloadDTO[]
}

export function fetchSolventData(): Promise<ISolventData> {
    return axios.get('/api/eluent/solvent-data')
        .then((response) => {
            return response.data
        })
}

export function generateFileUploadUrlsForFilenames(filenames: string[]): Promise<CreateResponse[]> {
    return axios.post('/api/calculation/create', filenames)
        .then((response: AxiosResponse<CreateResponse[]>) => response.data)
}

export function uploadFileToPresignedUrl(file: File, id: number): Promise<void> {
    let formData = new FormData()
    formData.append('file', file)

    return axios.post(`/api/calculation/upload/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
}

export function startCalculation(data: CalculationStartData): Promise<CalculationStartResponse> {
    return axios.post(`/api/calculation/start`, data, {validateStatus: status => status === 200 || status === 422})
        .then((response: AxiosResponse<CalculationStartResponse>) => response.data)
}

export function fetchCalculationResult(code: TCalculationCode): Promise<CalculationResultResponse> {
    return axios.get(`/api/calculation/status/${code}`)
        .then((response: AxiosResponse<CalculationResultResponse>) => response.data)
}
