import React from 'react';
import { ScaleLoader } from 'react-spinners';

function QuantemScaleLoader(props: { colorOverride?: string}) {
    const color = props.colorOverride === undefined ? "#323789" : props.colorOverride
    return (
        <ScaleLoader color={color} height={35} width={4} radius={2} margin={2} speedMultiplier={0.5}/>
    );
}

export default QuantemScaleLoader;
