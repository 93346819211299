import React from 'react'
import { useLocalization } from '../../../../hooks/useLocalization'
import { GradientStep } from '../../../../model/eluent/GradientStep'
import CalculationCard from '../../components/CalculationCard'
import DeadTimeRow from './DeadTimeRow'
import GradientEntryRow from './GradientEntryRow'

interface GradientCardProps {
    deadTime: number;
    setDeadTime: (deadTime: number) => void;
    gradientSteps: GradientStep[];
    setGradientSteps: (steps: GradientStep[]) => void;
}

function GradientCard(props: GradientCardProps) {

    const l10n = useLocalization().calculation.step1.gradient

    function updateStepAtIndex(step: GradientStep, index: number) {
        if (props.gradientSteps.length <= index) {
            return
        }
        const steps = props.gradientSteps
        steps[index] = step
        props.setGradientSteps(steps)
    }

    function removeStepAtIndex(index: number) {
        if (props.gradientSteps.length <= index) {
            return
        }
        const steps = props.gradientSteps
        steps.splice(index, 1)
        props.setGradientSteps(steps)
    }

    function addStepAtIndex(index: number) {
        if (props.gradientSteps.length <= index) {
            return
        }
        const newStep = {
            startTime: props.gradientSteps[index].startTime,
            organicPhaseModifierPercent: props.gradientSteps[index].organicPhaseModifierPercent
        }
        let steps = props.gradientSteps
        steps.splice(index + 1, 0, newStep)
        props.setGradientSteps(steps)
    }

    return (
        <CalculationCard className="min-h-28" titleClassName="w-1/4 mt-11 ml-16 text-b2"
                         containerClassName="w-3/4 my-2 mr-16" title={l10n.title}>
            <div className="flex flex-col space-y-2.5">
                <DeadTimeRow deadTime={props.deadTime} updateDeadTime={props.setDeadTime}/>
                {props.gradientSteps.map((step, index) => {
                    return (
                        <GradientEntryRow
                            key={index}
                            index={index}
                            step={step}
                            updateStepAtIndex={updateStepAtIndex}
                            removeStepAtIndex={removeStepAtIndex}
                            addStepAtIndex={addStepAtIndex}
                        />
                    )
                })}
            </div>
        </CalculationCard>
    )
}

export default GradientCard
