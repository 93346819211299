import React from 'react';
import { ReactComponent as ErrorIcon } from '../../assets/img/icons/error-icon.svg';
import { useLocalization } from '../../hooks/useLocalization';

interface HistoryErrorProps {
    error: Error
}

function HistoryError(props: HistoryErrorProps) {
    const l10n = useLocalization().history.error;

    return (
        <div className="rounded bg-error-50 border border-error-200 h-20 mx-20 flex">
            <span className="ml-4 mt-4"><ErrorIcon /></span>
            <div className="mx-4 my-auto flex flex-col space space-y-1">
                <span className="text-b1 text-textDark-100 font-semibold">{l10n.title}</span>
                <span className="text-b2 text-textDark-200 font-normal">{props.error.message}</span>
            </div>
        </div>
    );
}

export default HistoryError;
