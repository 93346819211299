import React, { ChangeEvent } from 'react'

export interface GradientColumnProps {
    label?: string;
    labelSpan?: JSX.Element;
    unit: string;
    value?: number;
    min?: number;
    max?: number;

    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

function GradientColumn(props: GradientColumnProps) {

    function renderLabel() {
        if (props.labelSpan) {
            return props.labelSpan
        }

        return <span className="text-c1 font-semibold capitalize">{props.label}</span>
    }

    return (
        <div className="flex flex-col space-y-2 h-full">
            <div className="flex justify-between w-full">
                {renderLabel()}
                <span className="text-c1 font-normal text-textDark-200 lowercase">{props.unit}</span>
            </div>
            <input
                className="w-full h-[40px] text-b2 border rounded-md border-background-light-100 p-2"
                type="number"
                step="1"
                min={props.min}
                max={props.max}
                lang="en-US"
                value={props.value}
                onChange={props.onChange}
            />
        </div>
    )
}

export default GradientColumn
