import { Listbox, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { ReactComponent as ChevronDown } from '../../assets/img/icons/chevrons/chevron-down.svg'

export interface DropdownListItem {

    title: string;
    object: any;
}

export interface DropdownListProps {
    className?: string;
    items: DropdownListItem[];
    title: string;
    getSelected: (() => DropdownListItem | undefined);
    setSelected: ((selected: DropdownListItem) => void);
}

function DropdownListButton(props: { title: string }) {
    return <Listbox.Button
        className="flex w-full h-full text-left border rounded-md border-background-light-100 box-border">
        <span className="flex-grow my-auto pl-2 text-textDark-100">{props.title}</span>
        <ChevronDown className="my-auto mr-2 w-5 h-5 text-gray-400" aria-hidden="true"/>
    </Listbox.Button>
}

function DropdownList(props: DropdownListProps) {
    return (
        <div className={props.className}>
            <Listbox value={props.getSelected()} onChange={props.setSelected}>
                <div className="relative h-full">
                    <DropdownListButton title={props.getSelected()?.title ?? props.title}/>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className="mt-2 max-h-64 overflow-y-auto bg-backgroundLight-100 rounded-lg shadow-lg border border-outline">
                            {props.items.map((item, index) => (
                                <Listbox.Option key={index}
                                                className="z-10 cursor-pointer bg-backgroundLight-100 hover:bg-hoverAlt select-none relative py-2 pl-4 pr-4"
                                                value={item}>
                                    {item.title}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    )
}

export default DropdownList
