import React from 'react';
import QuantemScaleLoader from '../../loadingIndicators/QuantemScaleLoader';
import { IButtonProps } from './IButtonProps';

export interface IBaseButtonProps extends IButtonProps {
    className: string;
    textClassName: string;
    outline?: true | false;
    outlineColor?: string;
}

function outlineClasses(outlineColor: string): string {
    return `${outlineColor} border-solid border disabled:bg-textLight-100 disabled:border-otherLight-100`;
}

function constructClassesFromProps(props: IBaseButtonProps): string {
    let viewClasses = `rounded-lg ${props.isLoading ? "" : "hover:bg-otherLight-400"} ${props.className} `;

    if (props.outline !== undefined && props.outlineColor !== undefined && props.outline) {
        viewClasses += `${outlineClasses(props.outlineColor)}`;
    } else {
        viewClasses += 'disabled:bg-gray-25 disabled:text-textDark-300'
    }

    return viewClasses
}

function ButtonBase(props: IBaseButtonProps) {
    const ButtonTag: 'a' | 'button' = props.url && !props.isDisabled ? 'a' : 'button';

    function renderLoadingIndicator() {
        return (
            <div className="m-auto">
                <div className="scale-[0.5]">
                    <QuantemScaleLoader />
                </div>
            </div>
        )
    }

    function renderButtonContent() {
        return <div className="flex m-auto items-center space-x-2">
            {props.iconLeft !== undefined ?
                <span className={`${props.isDisabled ? 'text-textDark-300' : ''} ${props.textClassName}`} aria-disabled="true">{props.iconLeft}</span>
                : (<div/>)
            }
            <span className={`${props.isDisabled ? 'text-textDark-300' : ''} ${props.textClassName}`}>{props.text}</span>
            {props.iconRight !== undefined
                ? <span className={`${props.isDisabled ? 'text-textDark-300' : ''} ${props.textClassName}`} aria-disabled="true">{props.iconRight}</span>
                : (<div/>)
            }
        </div>;
    }

    return (
        <ButtonTag
            className={constructClassesFromProps(props)}
            type={props.type}
            onClick={props.onClick}
            disabled={props.isDisabled}
            target={props.target}
            href={props.url}
        >
            <div className="flex items-center">
                { (props.isLoading ?? false) ?  renderLoadingIndicator() : renderButtonContent()}
            </div>
        </ButtonTag>
    );
}

export default ButtonBase;
