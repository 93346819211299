import React from 'react'
import ButtonPrimaryOutline from '../../../components/buttons/primary/ButtonPrimaryOutline'
import DropdownList, { DropdownListItem } from '../../../components/dropdowns/DropdownList'
import { useLocalization } from '../../../hooks/useLocalization'
import { EluentTemplate } from '../../../model/eluent/EluentTemplate'
import CalculationCard from '../components/CalculationCard'

interface AnalysisConditionsProps {
    templates: EluentTemplate[];
    getSelectedTemplate: () => EluentTemplate | undefined;
    setSelectedTemplate: (template: EluentTemplate) => void;
    saveClicked: () => void;
    canSave: boolean;
}

function AnalysisConditionsCard(props: AnalysisConditionsProps) {

    const l10n = useLocalization().calculation.step1.analysisConditions

    function mapTemplatesToDropdownListItems(templates: EluentTemplate[]): DropdownListItem[] {
        return templates.map((template) => {
            return {
                title: template.name,
                object: template
            }
        })
    }

    function getSelected() {
        const selectedTemplate = props.getSelectedTemplate()
        if (selectedTemplate === undefined) {
            return undefined
        }
        return {
            title: selectedTemplate.name,
            object: selectedTemplate
        }
    }

    function setSelected(object: DropdownListItem) {
        props.setSelectedTemplate(object.object)
    }

    return (
        <CalculationCard title={l10n.title} className="h-28">
            <div className="flex flex-wrap space-x-8 sm:space-y-2 md:space-y-0">
                <DropdownList
                    className="text-b2 w-[250px] h-[40px]"
                    items={mapTemplatesToDropdownListItems(props.templates)}
                    title={l10n.title}
                    getSelected={getSelected}
                    setSelected={setSelected}
                />
                <ButtonPrimaryOutline
                    className="w-[150px] h-[40px] text-b2 font-semibold"
                    text={l10n.save}
                    isDisabled={!props.canSave}
                    onClick={(event) => {
                        event.preventDefault()
                        props.saveClicked()
                    }}
                />
            </div>
        </CalculationCard>
    )
}

export default AnalysisConditionsCard
