import React from 'react';
import { ReactComponent as ErrorIcon } from '../../../../assets/img/icons/error-icon.svg';
import ButtonBase from '../../../../components/buttons/base/ButtonBase';
import ButtonPrimaryOutline from '../../../../components/buttons/primary/ButtonPrimaryOutline';
import ModalDialog from '../../../../components/modal/ModalDialog';
import { useLocalization } from '../../../../hooks/useLocalization';

interface DuplicateConditionsDialogProps {
    isOpen: boolean;
    id: number;
    title: string;
    closeModal: () => void;
    saveClicked: (id: number, title: string) => void;
}

function DuplicateTemplateDialog(props: DuplicateConditionsDialogProps) {
    const l10n = useLocalization().calculation.step1.analysisConditions;

    return (
        <ModalDialog isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className="inline-block bg-backgroundLight-100 transition-all transform rounded p-4 flex-col md:min-w-[500px] space-y-4 shadow-modal">
                <div className="flex space-x-4">
                    <div className="flex rounded-full bg-error-50 h-7 w-7">
                        <span className="m-auto scale-75" aria-hidden="true"><ErrorIcon /></span>
                    </div>
                    <span className="my-auto text-h3 font-bold text-textDark-100 capitalize">{l10n.duplicateError}</span>
                </div>
                <div className="flex flex-col items-start">
                    <span className="text-b2 text-textDark-200 font-normal">{l10n.duplicateErrorInstructionsLine1}</span>
                    <span className="text-b2 text-textDark-200 font-normal">{l10n.duplicateErrorInstructionsLine2}</span>
                </div>
                <div className="flex space-x-2 justify-end">
                    <ButtonPrimaryOutline
                        className="h-10 text-primary-600"
                        text={l10n.save}
                        onClick={(event) => {
                            event.preventDefault();
                            props.saveClicked(props.id, props.title)
                        }}
                    />
                    <ButtonBase
                        className="h-10 bg-primary-600 hover:bg-hoverDark hover:text-textDark-100 border border-primary-600 text-textLight-100 w-[83px]"
                        textClassName="text-b2 font-semibold"
                        text={l10n.cancel}
                        onClick={(event) => {
                            event.preventDefault();
                            props.closeModal();
                        }}
                    />
                </div>
            </div>
        </ModalDialog>
    );
}

export default DuplicateTemplateDialog;
