import axios, { AxiosResponse } from 'axios';
import { EluentTemplate } from '../model/eluent/EluentTemplate';

export function fetchGradientTemplates(): Promise<EluentTemplate[]> {
    return axios.get('/api/eluent/templates')
        .then( (response) => { return response.data })
}

export function saveNewEluentTemplate(template: EluentTemplate): Promise<EluentTemplate> {
    return axios.post(`/api/eluent/templates`, template)
        .then((response: AxiosResponse) => response.data )
}

export function deleteEluentTemplate(id: number): Promise<EluentTemplate> {
    return axios.delete(`/api/eluent/templates/${id}`)
        .then((response) => response.data)
}
