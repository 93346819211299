import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckmarkIcon } from '../../../assets/img/icons/checkmark-icon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/img/icons/error-icon.svg';
import ButtonPrimaryOutline from '../../../components/buttons/primary/ButtonPrimaryOutline';
import QuantemScaleLoader from '../../../components/loadingIndicators/QuantemScaleLoader';
import { downloadAndZip } from '../../../helpers/JsZip';
import { useLocalization } from '../../../hooks/useLocalization';
import { CalculationStatus } from '../../../model/calculation/CalculationStatus';
import { CalculationResultResponse } from '../../../providers/EluentDataProvider';
import { checkIfCalculationFinished } from '../../../services/CalculationService';

interface CalculationStep3Props {
    calculationCode: string,
    calculationFinishHandler: (error?: Error) => void,
    hasFinished: boolean,
    finishedWithError?: Error
}

enum CalculationState {
    calculating,
    succeeded,
    failed
}

class CalculationError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'CalculationError'
    }
}

function CalculationStep3(props: CalculationStep3Props) {

    const [calculationState, setCalculationState] = useState<CalculationState>(CalculationState.calculating);
    const [calculationError, setCalculationError] = useState<Error>();
    const [results, setResults] = useState<CalculationResultResponse>();
    const l10n = useLocalization().calculation.step3;
    const navigate = useNavigate()

    useEffect(() => {
        if (props.hasFinished) {
            if (props.finishedWithError !== undefined) {
                setCalculationState(CalculationState.failed)
            } else {
                setCalculationState(CalculationState.succeeded)
            }
        } else {
            startPolling()
        }
    }, [])

    function startPolling() {
        let refreshCount = 0
        const interval = window.setInterval(() => {
            if (refreshCount >= 4) {
                clearInterval(interval)
                navigate("/history")
            }
            refreshCount += 1
            checkIfCalculationFinished(props.calculationCode)
                .then((response) => handleCalculationResultResponse(response, interval))
                .catch((error) => {
                    props.calculationFinishHandler(error)
                    clearInterval(interval)
                    setCalculationState(CalculationState.failed)
                })
        }, 5000)
    }

    function handleCalculationResultResponse(response: CalculationResultResponse, interval: number) {
        if (response.status === CalculationStatus.failed) {
            clearInterval(interval)
            props.calculationFinishHandler(new CalculationError(''))
            setCalculationState(CalculationState.failed)
        }

        if (response.status === CalculationStatus.finished) {
            clearInterval(interval)
            props.calculationFinishHandler()
            setCalculationState(CalculationState.succeeded)
            setResults(response)
        }
    }

    function renderIcon() {
        switch (calculationState) {
            case CalculationState.calculating:
                return (
                    <div className="scale-[1] mt-auto">
                        <QuantemScaleLoader />
                    </div>
                )
            case CalculationState.succeeded:
                return (
                    <div className="flex bg-primary-800 rounded-full h-8 w-8 mt-auto">
                        <CheckmarkIcon className="m-auto fill-textLight-100"/>
                    </div>
                )

            case CalculationState.failed:
                return (
                    <div className="scale-150 flex h-9 w-9 mt-auto">
                        <ErrorIcon className="m-auto"/>
                    </div>
                )
        }
    }

    function renderTitle(): JSX.Element {
        const spanClass = "text-c1 font-semibold text-textDark-100";
        switch (calculationState) {
            case CalculationState.calculating:
                return <span className={spanClass}>{l10n.calculating}</span>

            case CalculationState.succeeded:
                return <span className={spanClass}>{l10n.calculationDone}</span>

            case CalculationState.failed:
                return <span className={spanClass}>{l10n.calculationFailed}</span>
        }
    }

    function saveClicked() {
        if (results?.downloadUrls?.length === 1) {
            const url = results.downloadUrls[0].url
            if (url !== undefined) {
                window.location.href = url;
            }
        } else {
            downloadAndZip(results?.downloadUrls?.map((result) => {
                return {
                    fileName: result.fileName,
                    url: result.url
                }
            }) ?? [])
        }
    }

    function renderMessage(): JSX.Element {
        switch (calculationState) {
            case CalculationState.calculating:
                return <div>
                    <span className="text-c1 text-textDark-100 font-normal">{l10n.calculatingDisclaimer}</span>
                    <span className="text-c1 text-primary-600 font-normal cursor-pointer" onClick={ event => {
                        event.preventDefault()
                        navigate("/history")
                    }}>{` ${l10n.historyPage}.`}</span>
                </div>

            case CalculationState.succeeded:
                return (
                    <div className="text-c1 text-textDark-100 font-normal">
                        <span>{l10n.calculationDoneDisclaimer}</span>
                        <span className="text-primary-600 cursor-pointer" onClick={ event => {
                            event.preventDefault()
                            navigate('/history')
                        }}>{` ${l10n.historyPage}`}</span>
                        <span>{l10n.calculationsDoneOrLabel}</span>
                        <span className="text-primary-600 cursor-pointer"
                            onClick={(event) => {
                                event.preventDefault();
                                saveClicked();
                            }}
                        >{l10n.calculationDoneDownloadFromHere}</span>
                    </div>
                )

            case CalculationState.failed:
                return (
                    <div>
                        <span className="text-c1 text-textDark-100 font-normal">{(calculationError !== undefined && calculationError.message !== '') ? l10n.errorIn : ""}</span>
                        <span className="text-c1 text-textDark-100 font-normal"> {calculationError?.message ?? ""} </span>
                        <a href="mailto:support@quantem.co" className="text-c1 text-primary-600 font-normal cursor-pointer">{l10n.contactUs}</a>
                    </div>
                )
        }
    }

    return (
        <div className="flex flex-col space-y-2">
            <div className="h-80 mx-2 rounded bg-backgroundLight-100 border border-primary-800 flex flex-col space-y-4 items-center pb-20">
                {renderIcon()}
                {renderTitle()}
                {renderMessage()}
            </div>
            <div className={`${props.hasFinished ? '' : 'hidden'} h-10 mx-2 flex`}>
                <ButtonPrimaryOutline className="ml-auto min-w-[150px] h-full text-b2 text-primary-600 font-semibold" text={l10n.newCalculation}  onClick={(event) => {
                    window.location.reload();
                }}/>
            </div>
        </div>
    );
}

export default CalculationStep3;
