import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import QuantemScaleLoader from './components/loadingIndicators/QuantemScaleLoader'
import { CoreRoutes } from './CoreRoutes'
import { useAuth } from './hooks/useAuth'
import AuthProvider from './providers/Auth/AuthProvider'
import LocalizationProvider from './providers/localization/LocalizationProvider'
import ViewBase from './views/base/ViewBase'

function Root() {
    const auth = useAuth()

    if (!auth.isAuthenticated && auth.isLoaded && auth.user === undefined) {
        window.location.replace('/api/login')
    } else if (auth.isLoaded && auth.isAuthenticated && auth.user !== undefined) {
        return (
            <ViewBase>
                <CoreRoutes/>
            </ViewBase>
        )
    }
    return (
        <div className="h-screen w-screen flex flex-col items-center">
            <div className="m-auto scale-150">
                <QuantemScaleLoader/>
            </div>
        </div>
    )
}

function App() {
    return (
        <div className="App">
            <AuthProvider>
                <LocalizationProvider>
                    <BrowserRouter>
                        <Root/>
                    </BrowserRouter>
                </LocalizationProvider>
            </AuthProvider>
        </div>
    )
}

export default App
