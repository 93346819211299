import React from 'react';

export interface CardProps {
    className?: string,
    children?: React.ReactNode
}

function Card(props: CardProps) {
    return (
        <div className={`${props.className} mx-2 bg-backgroundLight-100 rounded-md shadow-card`}>{props.children}</div>
    );
}

export default Card;
