import React, { useEffect, useState } from 'react'
import QuantemScaleLoader from '../../components/loadingIndicators/QuantemScaleLoader'
import { OrganizationListItemDto, OrganizationTier } from '../../model/admin/OrganizationListItemDto'
import { UserListItemDto } from '../../model/admin/UserListItemDto'
import { fetchOrganizationsList, fetchOrganizationTierList, fetchUsersList } from '../../providers/AdminProvider'
import HistoryError from '../history/HistoryError'
import AdministrationMain from './AdministrationMain'

enum AdminLoadingState {
    loading,
    done,
    error
}

function Admin() {

    const [loadingState, setLoadingState] = useState<AdminLoadingState>(AdminLoadingState.loading)
    const [loadingError, setLoadingError] = useState<Error>()
    const [users, setUsers] = useState<UserListItemDto[]>([])
    const [organizations, setOrganizations] = useState<OrganizationListItemDto[]>([])
    const [tiers, setTiers] = useState<OrganizationTier[]>([])

    useEffect(() => {
        Promise.all([
            fetchUsersList(),
            fetchOrganizationsList(),
            fetchOrganizationTierList()]
        )
            .then(results => {
                const users = results[0]
                const organizations = results[1]
                const tiers = results[2]
                setUsers(users)
                setOrganizations(organizations)
                setTiers(tiers)
                setLoadingState(AdminLoadingState.done)
            })
            .catch(error => {
                setLoadingError(error)
                setLoadingState(AdminLoadingState.error)
            })
    }, [])

    function updateUser(listItem: UserListItemDto) {
        const modified = users.map(user => {
            return listItem.id === user.id ? listItem : user
        })

        setUsers(modified)
        updateOrganizations()
    }

    function deleteUser(listItem: UserListItemDto) {
        const modified = users.filter(user => user.id !== listItem.id)
        setUsers(modified)
        updateOrganizations()
    }

    function updateOrganization(listItem: OrganizationListItemDto) {
        const modified = organizations.map(org => {
            return listItem.id === org.id ? listItem : org
        })

        setOrganizations(modified)
    }

    function deleteOrganization(listItem: OrganizationListItemDto) {
        const modified = organizations.filter(item => item.id !== listItem.id)
        setOrganizations(modified)
    }

    function updateOrganizations() {
        fetchOrganizationsList()
            .then(setOrganizations)
            .catch(error => {
                setLoadingError(error)
                setLoadingState(AdminLoadingState.error)
            })
    }

    function renderContent(): JSX.Element {
        switch (loadingState) {
            case AdminLoadingState.loading:
                return <div className="m-auto pt-96 py-4">
                    <QuantemScaleLoader/>
                </div>

            case AdminLoadingState.done:
                return <AdministrationMain
                    users={users}
                    organizations={organizations}
                    tiers={tiers}
                    updateUser={updateUser}
                    deleteUser={deleteUser}
                    updateOrganization={updateOrganization}
                    deleteOrganization={deleteOrganization}
                />

            case AdminLoadingState.error:
                return <HistoryError error={loadingError!}/>
        }
    }

    return (
        <div className="w-full h-full flex flex-col">
            {renderContent()}
        </div>
    )
}

export default Admin
