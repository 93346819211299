import React, { useEffect, useState } from 'react'
import QuantemScaleLoader from '../../components/loadingIndicators/QuantemScaleLoader'
import { CalculationStatus } from '../../model/calculation/CalculationStatus'
import { ISolventData } from '../../model/solventData/ISolventData'
import { fetchSolventData } from '../../providers/EluentDataProvider'
import { fetchHistory, HistoryResponse } from '../../providers/HistoryProvider'
import HistoryEmpty from './HistoryEmpty'
import HistoryError from './HistoryError'
import HistoryTable from './HistoryTable'

enum HistoryLoadingState {
    loading,
    empty,
    done,
    error
}

function History() {

    const [loadingState, setLoadingState] = useState<HistoryLoadingState>(HistoryLoadingState.loading)
    const [loadingError, setLoadingError] = useState<Error>()
    const [historyData, setHistoryData] = useState<HistoryResponse[]>([])
    const [interval, setInterval] = useState<number>()
    const [solventData, setSolventData] = useState<ISolventData>()

    useEffect(() => {
        Promise.all([fetchHistory(), fetchSolventData()])
            .then((response) => {
                handleStateFromHistoryData(response[0])
                setSolventData(response[1])
            })
            .catch((error) => {
                setLoadingError(error)
                setLoadingState(HistoryLoadingState.error)
            })
    }, [])

    function handleStateFromHistoryData(data: HistoryResponse[]) {
        if (data.length === 0) {
            setLoadingState(HistoryLoadingState.empty)
        } else {
            setHistoryData(data)
            setLoadingState(HistoryLoadingState.done)
            if (interval === undefined && data.some((row => row.status !== CalculationStatus.finished && row.status !== CalculationStatus.failed))) {
                startPolling()
            } else {
                clearInterval(interval)
            }
        }
    }

    function startPolling() {
        const interval = window.setInterval(() => {
            fetchHistory()
                .then(newData => {
                    const data = historyData
                    newData.forEach(historyRow => {
                        const index = data.findIndex(old => {
                            return old.id === historyRow.id
                        })
                        if (index === -1) {
                            data.push(historyRow)
                        } else {
                            const oldEntry = data[index]
                            if (oldEntry.status !== historyRow.status) {
                                window.location.reload()
                            }
                            data[index] = historyRow
                        }
                    })
                    setHistoryData([])
                    setHistoryData([...data])
                    setLoadingState(HistoryLoadingState.done)
                    if (data.every(row => row.status === CalculationStatus.finished || row.status === CalculationStatus.failed)) {
                        clearInterval(interval)
                        window.location.reload()
                    }
                })
        }, 5000)
        setInterval(interval)
    }

    function renderContent(): JSX.Element {
        switch (loadingState) {
            case HistoryLoadingState.loading:
                return <div className="m-auto pt-96">
                    <QuantemScaleLoader/>
                </div>

            case HistoryLoadingState.done:
                return <HistoryTable history={historyData} solventData={solventData}/>

            case HistoryLoadingState.error:
                return <HistoryError error={loadingError!}/>

            case HistoryLoadingState.empty:
                return <HistoryEmpty/>
        }
    }

    return (
        <div className="w-full h-full flex flex-col py-4">
            {renderContent()}
        </div>
    )
}

export default History
