import React, { ChangeEvent } from 'react'
import { useLocalization } from '../../../../hooks/useLocalization'
import GradientColumn from './GradientColumn'

interface DeadTimeRowProps {
    deadTime: number;
    updateDeadTime: (deadTime: number) => void;
}

function DeadTimeRow(props: DeadTimeRowProps) {

    const l10n = useLocalization().calculation.step1.gradient

    function deadTimeChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        props.updateDeadTime(event.target.valueAsNumber)
    }

    function labelRow(): JSX.Element {
        return <span className="text-c1 font-semibold">
            <span>{l10n.deadTime} </span>
            <span className="italic">{l10n.deadTimeT0}</span>
        </span>
    }

    return (
        <div className="flex space-x-8 h-16">
            <GradientColumn
                labelSpan={labelRow()}
                unit={l10n.deadTimeUnit}
                value={props.deadTime}
                onChange={deadTimeChangeHandler}
            />
        </div>
    )
}

export default DeadTimeRow
