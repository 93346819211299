import React from 'react'
import DropdownList, { DropdownListItem } from '../../../components/dropdowns/DropdownList'
import { useLocalization } from '../../../hooks/useLocalization'
import { IOrganicPhaseModifier } from '../../../model/solventData/IOrganicPhaseModifier'
import { IWaterPhaseAdditive } from '../../../model/solventData/IWaterPhaseAdditive'
import CalculationCard from '../components/CalculationCard'

interface SolventCardProps {
    waterPhaseAdditives: IWaterPhaseAdditive[];
    organicPhaseModifiers: IOrganicPhaseModifier[];
    currentWaterPhaseAdditive?: IOrganicPhaseModifier;
    setWaterPhaseAdditive: (modifier: IOrganicPhaseModifier) => void;
    currentOrganicPhaseModifier?: IOrganicPhaseModifier;
    setOrganicPhaseModifier: (modifier: IOrganicPhaseModifier) => void;
    currentPHValue?: number;
    setCurrentPHValue: (value: number) => void;
}

function SolventCard(props: SolventCardProps) {

    const l10n = useLocalization().calculation.step1.solvent

    function additivesAsItems(): DropdownListItem[] {
        return props.waterPhaseAdditives.map((additive) => {
            return {
                title: additive.readableName,
                object: additive
            }
        })
    }

    function selectedWaterPhaseAdditive(): DropdownListItem {
        return {
            title: props.currentWaterPhaseAdditive?.readableName ?? l10n.waterPhaseAdditive,
            object: props.currentWaterPhaseAdditive
        }
    }

    function setSelectedWaterPhaseAdditive(selected: DropdownListItem) {
        props.setWaterPhaseAdditive(selected.object)
    }

    function organicsAsItems(): DropdownListItem[] {
        return props.organicPhaseModifiers.map((additive) => {
            return {
                title: additive.readableName,
                object: additive
            }
        })
    }

    function selectedOrganicPhaseModifier(): DropdownListItem {
        return {
            title: props.currentOrganicPhaseModifier?.readableName ?? l10n.organicPhase,
            object: props.currentOrganicPhaseModifier
        }
    }

    function setSelectedOrganicPhaseModifier(selected: DropdownListItem) {
        props.setOrganicPhaseModifier(selected.object)
    }

    return (
        <CalculationCard className="min-h-28" title={l10n.title} containerClassName="w-3/4 mr-16">
            <div className="flex flex-wrap space-x-8 mt-2.5">
                <div className="flex flex-col space-y-1 w-[250px] h-16">
                    <span className="text-c1 font-semibold capitalize">{l10n.waterPhaseAdditive}</span>
                    <DropdownList
                        className="text-b2 h-[40px]"
                        items={additivesAsItems()}
                        title={l10n.waterPhaseAdditive}
                        getSelected={selectedWaterPhaseAdditive}
                        setSelected={setSelectedWaterPhaseAdditive}
                    />
                </div>
                <div className="flex flex-col space-y-1 w-16 h-16">
                    <span className="text-c1 font-semibold">{l10n.pH}</span>
                    <input
                        className="w-full h-[40px] text-b2 text-center border rounded-md border-background-light-100 box-border p-1"
                        type="number"
                        step="0.1"
                        min="0"
                        max="14"
                        lang="en-US"
                        value={props.currentPHValue}
                        onChange={(event) => {
                            const value = event.target.valueAsNumber
                            if ((0 <= value && value <= 14) || isNaN(value)) {
                                props.setCurrentPHValue(value)
                            }
                        }}
                    />
                </div>
                <div className="flex flex-col space-y-1 w-[250px] h-16">
                    <span className="text-c1 font-semibold capitalize">{l10n.organicPhase}</span>
                    <DropdownList
                        className="text-b2 h-[40px]"
                        items={organicsAsItems()}
                        title={l10n.organicPhase}
                        getSelected={selectedOrganicPhaseModifier}
                        setSelected={setSelectedOrganicPhaseModifier}
                    />
                </div>
            </div>
        </CalculationCard>
    )
}

export default SolventCard
