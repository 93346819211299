import { Table } from 'flowbite-react'
import React from 'react'
import { useLocalization } from '../../../hooks/useLocalization'
import { OrganizationListItemDto } from '../../../model/admin/OrganizationListItemDto'
import { UserListItemDto } from '../../../model/admin/UserListItemDto'
import AdminUsersTableRow from './AdminUsersTableRow'

interface AdminUsersTableProps {
    users: UserListItemDto[],
    organizations: OrganizationListItemDto[],
    updateUser: (listItem: UserListItemDto) => void,
    deleteUser: (listItem: UserListItemDto) => void
}

function AdminUsersTable(props: AdminUsersTableProps) {

    let l10n = useLocalization().admin.users
    let headerStyles = 'bg-backgroundLight-100 text-c1 font-semibold text-textDark-100'

    function organizationForUser(user: UserListItemDto): OrganizationListItemDto {
        return props.organizations.filter(org => org.id === user.organizationId)[0]
    }

    function renderRows(): JSX.Element {
        return (
            <Table.Body className="divide-y">
                {props.users.map(user => {
                    return (
                        <AdminUsersTableRow
                            user={user}
                            currentOrganization={organizationForUser(user)}
                            organizations={props.organizations}
                            updateUser={props.updateUser}
                            deleteUser={props.deleteUser}
                        />
                    )
                })}
            </Table.Body>
        )
    }

    return (
        <Table hoverable>
            <Table.Head className="h-12">
                <Table.HeadCell className={`w-12 h-12 ${headerStyles}`}>
                    <span className={headerStyles}>{l10n.userId}</span>
                </Table.HeadCell>
                <Table.HeadCell className={`w-72 h-12 ${headerStyles}`}>
                    <span className={headerStyles}>{l10n.user}</span>
                </Table.HeadCell>
                <Table.HeadCell className={`w-72 ${headerStyles}`}>
                    <span className={headerStyles}>{l10n.email}</span>
                </Table.HeadCell>
                <Table.HeadCell className={`w-72 ${headerStyles}`}>
                    <span className={headerStyles}>{l10n.organization}</span>
                </Table.HeadCell>
                <Table.HeadCell className={`w-40 ${headerStyles}`}>
                    <span className={headerStyles}>{l10n.organizationId}</span>
                </Table.HeadCell>
                <Table.HeadCell className={`w-36 ${headerStyles}`}>
                    <span className={headerStyles}>{l10n.lastCalculation}</span>
                </Table.HeadCell>
                <Table.HeadCell className={`w-2 ${headerStyles}`}/>
            </Table.Head>
            {renderRows()}
        </Table>
    )
}

export default AdminUsersTable
