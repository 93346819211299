import React, { useState } from 'react';
import { ReactComponent as SaveIcon } from '../../../../assets/img/icons/save-icon.svg';
import ButtonBase from '../../../../components/buttons/base/ButtonBase';
import ButtonPrimaryOutline from '../../../../components/buttons/primary/ButtonPrimaryOutline';
import ModalDialog from '../../../../components/modal/ModalDialog';
import { useLocalization } from '../../../../hooks/useLocalization';

interface SaveConditionsDialogProps {
    isOpen: boolean;
    closeModal: () => void;
    saveClicked: (title: string) => void;
}

function SaveConditionsDialog(props: SaveConditionsDialogProps) {

    const [templateName, setTemplateName] = useState<string>();
    const l10n = useLocalization().calculation.step1.analysisConditions;

    function canSave(): boolean {
        return templateName !== undefined && templateName.trim() !== ''
    }

    return (
        <ModalDialog isOpen={props.isOpen} closeModal={props.closeModal}>
            <div className="inline-block bg-backgroundLight-100 transition-all transform rounded p-4 flex-col md:min-w-[500px] space-y-4 shadow-modal">
                <div className="flex space-x-4">
                    <span className="my-auto" aria-hidden="true"><SaveIcon /></span>
                    <span className="my-auto text-h3 font-bold text-textDark-100 capitalize">{l10n.saveTitle}</span>
                </div>
                <div>
                    <input
                        className="border placeholder-textDark-200 text-textDark-100 font-normal text-b2 p-2 rounded md:min-w-[250px]"
                        type="text"
                        value={templateName}
                        placeholder={l10n.savePlaceholder}
                        onChange={(event) => {
                            event.preventDefault();
                            const text = event.target.value;
                            setTemplateName(text);
                        }}
                    />
                </div>
                <div className="flex space-x-2 justify-center">
                    <ButtonPrimaryOutline
                        isDisabled={!canSave()}
                        className="h-10 text-primary-600"
                        text={l10n.save}
                        onClick={(event) => {
                            event.preventDefault();
                            if (canSave() && templateName !== undefined) {
                                props.saveClicked(templateName.trim())
                            }
                        }}
                    />
                    <ButtonBase
                        className="h-10 bg-primary-600 hover:bg-hoverDark hover:text-textDark-100 border border-primary-600 text-textLight-100 w-[83px]"
                        textClassName="text-b2 font-semibold"
                        text={l10n.cancel}
                        onClick={(event) => {
                            event.preventDefault();
                            props.closeModal();
                        }}
                    />
                </div>
            </div>
        </ModalDialog>
    );
}

export default SaveConditionsDialog;
