import React from 'react'
import DropdownList, { DropdownListItem } from '../../../components/dropdowns/DropdownList'
import { useLocalization } from '../../../hooks/useLocalization'
import { ConcentrationUnit } from '../../../model/solventData/ConcentrationUnit'
import CalculationCard from '../components/CalculationCard'

interface ConcentrationUnitCardProps {
    concentrationUnits: ConcentrationUnit[],
    selectedConcentrationUnit?: ConcentrationUnit,
    setCurrentConcentrationUnit: (selected: ConcentrationUnit) => void
}

export function ConcentrationUnitCard(props: ConcentrationUnitCardProps) {

    const l10n = useLocalization().calculation.step1

    function unitsAsItems(): DropdownListItem[] {
        return props.concentrationUnits.map((unit) => {
            return {
                title: unit.readableName,
                object: unit
            }
        })
    }

    function selectedUnitAsItem(): DropdownListItem {
        return {
            title: props.selectedConcentrationUnit?.readableName ?? l10n.concentrationUnit,
            object: props.selectedConcentrationUnit
        }
    }

    function setSelectedUnitFrom(item: DropdownListItem) {
        props.setCurrentConcentrationUnit(item.object)
    }

    return (
        <CalculationCard className="min-h-28 flex-grow" title={l10n.concentrationUnit} containerClassName="mt-7">
            <div className="flex flex-col space-y-1 w-[250px] h-16">
                <span className="text-c1 font-semibold capitalize">{l10n.waterPhaseAdditive}</span>
                <DropdownList
                    className="text-b2 h-[40px]"
                    items={unitsAsItems()}
                    title={l10n.waterPhaseAdditive}
                    getSelected={selectedUnitAsItem}
                    setSelected={setSelectedUnitFrom}
                />
            </div>
        </CalculationCard>
    )
}
