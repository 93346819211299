import React from 'react'

interface IContentContainerProps {
    viewTitle: string;
    className?: string;
    children: React.ReactNode;
}

function ContentContainer(props: IContentContainerProps): JSX.Element {
    return (
        <div className={`${props.className} flex flex-col flex-1 w-full bg-backgroundLight-200 max-h-screen`}>
            <div className="bg-textLight-100 h-14 align-middle shadow-[0_2px_8px_0_rgba(0,0,0,0.12)]">
                <p className="pl-4 py-4 font-semibold text-base text-textDark-100">{props.viewTitle}</p>
            </div>
            <div className="w-full max-w-7xl mx-auto flex overflow-y-auto min-h-max">
                {props.children}
            </div>
        </div>
    )
}

export default ContentContainer
