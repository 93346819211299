import { RadioGroup } from '@headlessui/react'
import React from 'react'
import { ReactComponent as RadioEmpty } from '../../../assets/img/icons/radio-icons/radio-empty.svg'
import { ReactComponent as RadioFilled } from '../../../assets/img/icons/radio-icons/radio-filled.svg'
import { useLocalization } from '../../../hooks/useLocalization'
import { ESIMode } from '../../../model/eluent/ESIMode'
import CalculationCard from '../components/CalculationCard'

interface EsiModeProps {
    esiMode: ESIMode;
    possibleEsiModes: ESIMode[];
    setEsiMode: (esiMode: ESIMode) => void;
}

function EsiModeCard(props: EsiModeProps) {

    const l10n = useLocalization().calculation.step1.esiMode

    function translateEsiMode(esiMode: ESIMode): string {
        switch (esiMode) {
            case 'POSITIVE':
                return l10n.options.positive

            case 'NEGATIVE':
                return l10n.options.negative

            case 'DUAL':
                return l10n.options.dual
        }
    }

    return (
        <CalculationCard className="h-28 flex-grow" title={l10n.title}>
            <div className="text-b2">
                <RadioGroup value={props.esiMode} onChange={props.setEsiMode}>
                    {props.possibleEsiModes.map((esiMode, key) => {
                        return (
                            <RadioGroup.Option value={esiMode} key={key}>
                                {({checked}) => (
                                    <div className="mt-2 flex cursor-pointer">
                                        <span className="my-auto" aria-hidden="true">{checked ? <RadioFilled/> :
                                            <RadioEmpty/>}</span>
                                        <span
                                            className="my-auto ml-2 capitalize lowercase">{translateEsiMode(esiMode)}</span>
                                    </div>
                                )}
                            </RadioGroup.Option>
                        )
                    })}
                </RadioGroup>
            </div>
        </CalculationCard>
    )
}

export default EsiModeCard
