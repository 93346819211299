import React from 'react';
import { ReactComponent as CheckmarkIcon } from '../../assets/img/icons/checkmark-icon.svg';
import { useLocalization } from '../../hooks/useLocalization';

interface CalculationStepDisplayProps {
    currentStep: 1 | 2 | 3 | 4
}

function CalculationStep(props: { isActive: boolean, hasFinished: boolean, stepNumber: number, stepTitle: string }) {

    const circleBg = (props.isActive || props.hasFinished )? "bg-primary-600" : "bg-gray-200";

    function renderText() {
        if (props.hasFinished) {
            return <span className="mx-auto"><CheckmarkIcon className="fill-textLight-100"/></span>
        } else {
            return <span className="mx-auto">{props.stepNumber}</span>
        }
    }

    return (
        <div className="h-full w-36 flex flex-col items-center">
            <div className={`flex rounded-full w-8 h-8 ${circleBg} text-textLight-100 text-c1 text-center py-2`}>
                {renderText()}
            </div>
            <div className="mt-2 text-c1 font-semibold text-backgroundDark-50">{props.stepTitle}</div>
        </div>
    )
}

function CalculationStepDisplay(props: CalculationStepDisplayProps) {

    const l10n = useLocalization().calculation.stepNames;

    return (
        <div className="h-16 flex space-x-2 px-20">
            <CalculationStep
                isActive={props.currentStep === 1}
                hasFinished={props.currentStep > 1}
                stepNumber={1}
                stepTitle={l10n.step1}
            />
            <div className="bg-gray-100 h-px w-32 flex-grow mt-5" />
            <CalculationStep
                isActive={props.currentStep === 2}
                hasFinished={props.currentStep > 2}
                stepNumber={2}
                stepTitle={l10n.step2}
            />
            <div className="bg-gray-100 h-px w-32 flex-grow mt-5" />
            <CalculationStep
                isActive={props.currentStep === 3}
                hasFinished={props.currentStep > 3}
                stepNumber={3}
                stepTitle={l10n.step3}
            />
        </div>
    );
}

export default CalculationStepDisplay;
