import React from 'react';
import logo from "../../../assets/img/logos/quantem-logo-thin.svg";
import { ILogoProps } from './ILogoProps';

function LogoThinTransparent(props: ILogoProps) {
    return (
        <img className={props.className} src={logo} alt="Quantem Logo"/>
    );
}

export default LogoThinTransparent;
