import React from 'react'

interface IconButtonProps {
    className?: string
    onClick: () => void
    isDisabled: boolean
    children?: React.ReactNode
}

function IconButton(props: IconButtonProps) {

    const cursorClass = props.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'

    return (
        <button
            className={`${cursorClass} flex mt-auto p-2.5 rounded-md h-[40px] w-[40px] items-center justify-center text-center ${props.className ?? ''}`}
            disabled={props.isDisabled}
            onClick={(event) => {
                event.preventDefault()
                if (!props.isDisabled) {
                    props.onClick()
                }
            }}
        >
            {props.children}
        </button>
    )
}

export default IconButton
