import React from 'react'

export interface SegmentedControlItem {
    label: string
    activeIcon: JSX.Element
    icon: JSX.Element
    isActive: boolean
    selectedCallback: () => void
}

interface SegmentedControlProps {
    items: SegmentedControlItem[]
}

function SegmentedControlButton(props: { item: SegmentedControlItem }) {

    return (
        <div
            className={`
            ${
                props.item.isActive
                    ? 'bg-primary-50 text-primary-600'
                    : 'cursor-pointer text-textDark-200 text-textDark-200 hover:bg-backgroundLight-300'
            } h-10 rounded-lg flex`}

            onClick={(event) => {
                event.preventDefault()
                props.item.selectedCallback()
            }}
        >
            <p
                className="px-4 py-2 my-auto flex space-x-2">
                <span className="mt-1">{props.item.isActive ? props.item.activeIcon : props.item.icon}</span>
                <span>{props.item.label}</span></p>
        </div>
    )
}

function SegmentedControl(props: SegmentedControlProps) {
    return (
        <div className="flex h-10 w-full justify-center gap-2 mb-2">
            {props.items.map(item => {
                return <SegmentedControlButton item={item}/>
            })}
        </div>
    )
}

export default SegmentedControl
