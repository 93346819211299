import React from 'react';
import { ReactComponent as InfoIcon } from '../../assets/img/icons/info-icon-primary.svg';
import { useLocalization } from '../../hooks/useLocalization';

function HistoryEmpty() {
    const l10n = useLocalization().history.empty;

    return (
        <div className="rounded bg-primary-50 border border-primary-200 h-20 mx-20 flex">
            <span className="ml-4 mt-4"><InfoIcon /></span>
            <div className="mx-4 my-auto flex flex-col space space-y-1">
                <span className="text-b1 text-textDark-100 font-semibold">{l10n.title}</span>
                <span className="text-b2 text-textDark-200 font-normal">{l10n.label}</span>
            </div>
        </div>
    );
}

export default HistoryEmpty;
