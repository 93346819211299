import React from 'react';
import { Navbar } from '../../components/navigation/Navbar';

interface IViewBaseProps {
    children?: React.ReactNode
}

function ViewBase(props: IViewBaseProps) {
    return (
        <div className="relative min-h-screen flex">
            <Navbar/>
            {props.children}
        </div>
    );
}

export default ViewBase;
