import { Table } from 'flowbite-react'
import React from 'react'
import { useLocalization } from '../../../hooks/useLocalization'
import { OrganizationListItemDto, OrganizationTier } from '../../../model/admin/OrganizationListItemDto'
import AdminOrganizationRow from './AdminOrganizationRow'

interface AdminOrganizationsTableProps {
    organizations: OrganizationListItemDto[]
    tiers: OrganizationTier[],
    updateOrganization: (listItem: OrganizationListItemDto) => void
    deleteOrganization: (listItem: OrganizationListItemDto) => void
}

function AdminOrganizationsTable(props: AdminOrganizationsTableProps) {

    let l10n = useLocalization().admin.organizations
    let headerStyles = 'bg-backgroundLight-100 text-c1 font-semibold text-textDark-100'

    function renderRows(): JSX.Element {
        return (<Table.Body className="divide-y">
            {props.organizations.map(org => <AdminOrganizationRow
                organization={org}
                tiers={props.tiers}
                updateOrganization={props.updateOrganization}
                deleteOrganization={props.deleteOrganization}
            />)}
        </Table.Body>)
    }

    return (
        <Table hoverable>
            <Table.Head>
                <Table.HeadCell className={headerStyles}>
                    <span className={headerStyles}>{l10n.organizationId}</span>
                </Table.HeadCell>
                <Table.HeadCell className={headerStyles}>
                    <span className={headerStyles}>{l10n.organization}</span>
                </Table.HeadCell>
                <Table.HeadCell className={headerStyles}>
                    <span className={headerStyles}>{l10n.userCount}</span>
                </Table.HeadCell>
                <Table.HeadCell className={headerStyles}>
                    <span className={headerStyles}>{l10n.calculationsDone}</span>
                </Table.HeadCell>
                <Table.HeadCell className={headerStyles}>
                    <span className={headerStyles}>{l10n.calculationsRemaining}</span>
                </Table.HeadCell>
                <Table.HeadCell className={headerStyles}>
                    <span className={headerStyles}>{l10n.tier}</span>
                </Table.HeadCell>
                <Table.HeadCell className={headerStyles}/>
                {/*<Table.HeadCell className={headerStyles}>*/}
                {/*    {l10n.quotaEnds}*/}
                {/*</Table.HeadCell>*/}
                {/*<Table.HeadCell className={headerStyles}>*/}
                {/*    {l10n.quotaLeft}*/}
                {/*</Table.HeadCell>*/}
                {/*<Table.HeadCell className={headerStyles}>*/}
                {/*    {l10n.quotaUsed}*/}
                {/*</Table.HeadCell>*/}
            </Table.Head>
            {renderRows()}
        </Table>
    )
}

export default AdminOrganizationsTable
