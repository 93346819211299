import bigDecimal from 'js-big-decimal'
import React, { ChangeEvent } from 'react'
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/plus-icon.svg'
import { ReactComponent as TrashIconPrimary } from '../../../../assets/img/icons/trash-icon-primary.svg'
import { useLocalization } from '../../../../hooks/useLocalization'
import { GradientStep } from '../../../../model/eluent/GradientStep'
import GradientColumn from './GradientColumn'

interface GradientEntryRowProps {
    index: number;
    step: GradientStep;
    updateStepAtIndex: (step: GradientStep, index: number) => void;
    removeStepAtIndex: (index: number) => void;
    addStepAtIndex: (index: number) => void;
}

function GradientEntryRow(props: GradientEntryRowProps) {

    const l10n = useLocalization().calculation.step1.gradient

    function timeChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        const step = props.step
        step.startTime = event.target.valueAsNumber
        props.updateStepAtIndex(step, props.index)
    }

    function waterPhaseChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        const step = props.step
        step.organicPhaseModifierPercent = 100 - event.target.valueAsNumber
        props.updateStepAtIndex(step, props.index)
    }

    function organicModifierChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        const step = props.step
        step.organicPhaseModifierPercent = event.target.valueAsNumber
        props.updateStepAtIndex(step, props.index)
    }

    function addButtonClicked() {
        props.addStepAtIndex(props.index)
    }

    function removeButtonClicked() {
        props.removeStepAtIndex(props.index)
    }

    function renderRemoveButton() {
        if (props.index !== 0) {
            return <button
                className="flex mt-auto p-2.5 hover:bg-hover border border-primary-600 rounded-md h-[40px] w-[40px] text-center"
                onClick={(event) => {
                    event.preventDefault()
                    removeButtonClicked()
                }}
            >
                    <span className="m-auto text-center" aria-disabled="true">
                        <TrashIconPrimary className="fill-primary-600"/>
                    </span>
            </button>
        }
    }

    function waterPhaseValue(): number | undefined {
        if (isNaN(props.step.organicPhaseModifierPercent)) {
            return undefined
        }
        const bigDecimalValue = new bigDecimal(props.step.organicPhaseModifierPercent)
        return parseFloat(new bigDecimal(100).subtract(bigDecimalValue).getValue())
    }

    function organicModifierValue(): number | undefined {
        if (isNaN(props.step.organicPhaseModifierPercent)) {
            return undefined
        }

        return parseFloat(new bigDecimal(props.step.organicPhaseModifierPercent).getValue())
    }

    return (
        <div className="flex space-x-8 h-16">
            <GradientColumn
                label={l10n.time}
                unit={l10n.timeUnit}
                value={props.step.startTime}
                onChange={timeChangeHandler}
            />
            <GradientColumn
                label={l10n.waterPhase}
                unit={l10n.waterPhaseUnit}
                value={waterPhaseValue()}
                onChange={waterPhaseChangeHandler}
            />
            <GradientColumn
                label={l10n.organicPhase}
                unit={l10n.organicPhaseUnit}
                value={organicModifierValue()}
                onChange={organicModifierChangeHandler}
            />
            <div className="flex align-bottom space-x-2">
                {renderRemoveButton()}
                <button
                    className="flex mt-auto bg-primary-600 p-2.5 hover:bg-hover border border-primary-600 rounded-md h-[40px] w-[40px] text-center"
                    onClick={(event) => {
                        event.preventDefault()
                        addButtonClicked()
                    }}
                >
                    <span className="m-auto text-center" aria-disabled="true">
                        <PlusIcon/>
                    </span>
                </button>
            </div>
        </div>
    )
}

export default GradientEntryRow
