import React from 'react';
import Card from '../card/Card';
import QuantemScaleLoader from './QuantemScaleLoader';

function MainLoadingIndicatorCard(props: { className?: string }) {
    return (
        <Card className={`${props.className} flex flex-col items-center`}>
            <div className="m-auto scale-150">
                <QuantemScaleLoader />
            </div>
        </Card>
    );
}

export default MainLoadingIndicatorCard;
