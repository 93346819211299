import axios from 'axios'
import { OrganizationListItemDto, OrganizationTier } from '../model/admin/OrganizationListItemDto'
import { UserListItemDto } from '../model/admin/UserListItemDto'

export function fetchUsersList(): Promise<UserListItemDto[]> {
    return axios.get('/api/admin/users')
        .then(response => response.data)
}

export function fetchOrganizationsList(): Promise<OrganizationListItemDto[]> {
    return axios.get('/api/admin/organizations')
        .then((response) => response.data)
}

export function fetchOrganizationTierList(): Promise<OrganizationTier[]> {
    return axios.get('/api/admin/tiers')
        .then((response) => response.data)
}

export function updateUserListItem(listItem: UserListItemDto): Promise<UserListItemDto> {
    return axios.put('/api/admin/user', listItem)
        .then(response => response.data)
}

export function updateOrganization(listItem: OrganizationListItemDto): Promise<OrganizationListItemDto> {
    return axios.put('/api/admin/organization', listItem)
        .then((response) => {
            return response.data
        })
}

export function deleteUserListItem(listItem: UserListItemDto): Promise<void> {
    return axios.delete(`/api/admin/users/${listItem.id}`)
        .then(response => response.data)
}

export function deleteOrganizationListItem(listItem: OrganizationListItemDto): Promise<void> {
    return axios.delete(`/api/admin/organizations/${listItem.id}`)
        .then(response => response.data)
}
