import React from 'react';
import ButtonBase from '../base/ButtonBase';
import { IButtonPrimaryProps, TButtonPrimaryVariant } from './IButtonPrimaryProps';

function ButtonPrimaryOutline(props: IButtonPrimaryProps) {
    const variantStyle = function (variant?: TButtonPrimaryVariant): string {
        if (variant === '3') {
            return 'bg-primary-50'
        } else if (variant === '2') {
            return 'bg-primary-25'
        } else {
            return 'bg-textLight-100'
        }
    }

    function textClasses() {
        if (props.isDisabled) {
            return "text-textDark-300 font-semibold"
        } else if (props.variant === '3') {
            return "text-textPrimary-900 font-semibold"
        } else {
            return "text-textPrimary-600 font-semibold"
        }
    }

    return (
        <ButtonBase
            className={`${props.className} px-4 py-auto ${variantStyle(props.variant)}`}
            textClassName={textClasses()}
            outline={true}
            outlineColor='border-primary-600'
            text={props.text}
            type={props.type}
            onClick={props.onClick}
            isDisabled={props.isDisabled}
            isLoading={props.isLoading}
            target={props.target}
            url={props.url}
            iconLeft={props.iconLeft}
            iconRight={props.iconRight}
        />
    );
}

export default ButtonPrimaryOutline;
